<template>
  <div class="members">
    <van-empty v-if="!members || members.length < 1" description="暂无用户" />
    <van-pull-refresh v-model="pullLoading" @refresh="getMember">
      <van-list v-model="loading" :finished="finished" @load="getMember">
        <div
          v-for="member in members"
          :key="member.uid"
          :class="{ active: member.uid === active.uid }"
          class="member"
          @click="selected(member)"
        >
          <div class="icon">
            <van-image :src="member.icon">
              <template v-slot:error>
                <img :src="null | userIconFix" />
              </template>
            </van-image>
            <i
              v-if="member.role === 1 && member.rebate_setting > 0"
              class="role-admin"
            />
            <!-- <i v-if="member.role === 1" class="role-leader" /> -->
          </div>
          <div class="name van-ellipsis">
            {{ member.username }}
          </div>
          <div class="uid">ID: {{ member.uid }}</div>
        </div>
      </van-list>
    </van-pull-refresh>

    <van-popup
      v-model="showDetail"
      @closed="closed"
      overlay-class="member-overlay"
      round
      closeable
      position="bottom"
    >
      <van-cell-group v-if="active" class="content" :border="false">
        <van-cell class="closebtn-placeholder" />
        <van-cell title="开袋次数" :value="active.open_box_count" />
        <van-cell title="开袋消费" :value="'￥' + active.open_box_cost" />
        <!--若该用户是代理-->
        <div v-if="active.role > 0">
          <van-cell title="下级用户数" :value="active.branch_num" />
          <van-cell
            title="下级累积开盒次数"
            :value="active.branch_open_count"
          />
          <van-cell
            title="下级累积消费总额"
            :value="'￥' + active.branch_open_cost"
          />
          <van-cell title="累计返利" :value="active.total_rebate" />
          <van-cell
            title="向下级返利"
            placeholder="请输入返利百分比"
            class="set-rebate"
          >
            <template #default>
              <van-field v-model="active.rebate_setting" type="digit" />
              <span class="unit">%</span>
              <van-button
                size="small"
                :loading="setRebateLoading"
                type="info"
                @click="setRebate(active.rebate_setting)"
                >确定</van-button
              >
            </template>
          </van-cell>
        </div>
        <div v-else class="end">
          <van-button
            round
            block
            type="warning"
            text="设为代理"
            size="small"
            @click="setAgent(active)"
          />
        </div>
        <!--/若该用户是代理-->
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "Member",
  data() {
    return {
      active: {},
      members: [],
      rebate: "",
      setRebateLoading: false,
      showDetail: false,
      loading: false,
      finished: false,
      pullLoading: false,
    };
  },
  created() {},
  methods: {
    delMember(member) {
      this.$dialog
        .confirm({
          title: "确认删除成员",
          message: member.username + "（ID: " + member.uid + "）",
        })
        .then(() => {
          this.$toast.loading({
            forbidClick: true,
            duration: 0,
          });
          setTimeout(() => {
            this.$toast.success("删除成功");
            for (let i = 0; i < this.members.length; i++) {
              if (this.members[i].uid === member.uid) {
                this.members.splice(i, 1);
                break;
              }
            }
            this.active = null;
            this.showDetail = false;
          }, 300);
        })
        .catch(() => {});
    },
    setRebate(rebate_setting) {
      if (!rebate_setting || rebate_setting > 100 || rebate_setting < 0) {
        this.$toast("请设置0-100间的数值");
        return false;
      }
      this.setRebateLoading = true;
      this.$ajax
        .post("/agent/agent/set/", {
          uid: this.active.uid,
          rebate_setting: rebate_setting,
        })
        .then((res) => {
          this.$toast.success("设置成功");
          this.setRebateLoading = false;
          this.updateMember(res.data.user);
        });
    },
    closed() {
      this.active = {};
    },
    setAgent(member) {
      this.$dialog
        .confirm({
          title: "设置代理",
          message: `是否将 ${member.user.username}（ID:${member.user.uid}）\n设置为代理？`,
          confirmButtonText: "设为代理",
        })
        .then(() => {
          this.$ajax
            .post("/agent/agent/add/", {
              uid: member.uid,
            })
            .then((res) => {
              this.$toast.success("设置成功");
              this.updateMember(res.data.user);
            });
        })
        .catch(() => {});
    },
    updateMember(member) {
      for (let i = 0; i < this.members.length; i++) {
        if (this.members[i].uid === member.uid) {
          this.members.splice(i, 1, member);
          break;
        }
      }
      this.showDetail = false;
    },
    selected(member) {
      this.active = member;
      this.$ajax
        .post("/agent/agent/get/", {
          uid: member.uid,
        })
        .then((res) => {
          this.active = res.data;
          this.showDetail = true;
        });
    },
    getMember() {
      this.$ajax.post("/agent/agent/branch/", {}).then((res) => {
        this.members = res.data;
        //以下是多余的，但如果以后要做分页会用到
        this.loading = false;
        this.pullLoading = false;
        this.finished = true;
      });
    },
  },
};
</script>
